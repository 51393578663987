import server from './request'

export const get = (url: any, params: any) => {
  return new Promise((resolve, reject) => {
    server.get(url, params).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export const post = (url: any, params: any) => {
  return new Promise((resolve, reject) => {
    server.post(url, params).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
