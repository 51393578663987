import { IState } from './typesState'

export default {
  loginUser: JSON.parse(localStorage.getItem('loginInfo') as string) || {},
  leftMenu: [],
  rememb: {},
  currentTab: 'indexlib',
  projectTypeList: [],
  projectList: [],
  token: ''
} as IState
