<template>
  <el-container>
    <div style="width: 100%;height: auto;">
      <el-affix position="top" :offset="0">
        <div style="width: 100%;height: 40px;display: flex;background: #FFFFFF;">
          <div style="width: 12%;height: 100%"></div>
          <div style="margin-top: 2px">
            <img style="width: 100%;height: 100%;cursor: pointer;object-fit: cover;object-position: left top;" src="@/assets/icons/logo1.png" />
          </div>
        </div>
        <el-header class="top">
          <div class="navigation-lift">
            <div class="navigation-logo">
<!--              <img src="@/assets/icons/logo.png" />-->
            </div>
            <div class="navigation-middle">
              <el-menu class="el-menu-demo" mode="horizontal" :default-active="activeIndex" @select="handleSelect">
                <template v-for="item in navigationLit" :key="item.categoryName">
                  <el-menu-item :index="item.categoryName" @click="onClickCategoryName(item)">
                    <template #title>
                      <div style="display: flex;">
                        <div v-if="item.nodes.length >0">
                          <el-popover placement="bottom" trigger="hover" width="100">
                            <template #reference>
                              <div @click="onClickCategoryName(item)">{{item.categoryName}}</div>
                            </template>
                            <div class="subset-options" v-for="el in item.nodes" :key="el" @click="onClickSubset(el,item)">
                              {{el.categoryName}}
                            </div>
                          </el-popover>
                        </div>
                        <div v-else>
                          <div>{{item.categoryName}}</div>
                        </div>
                      </div>
                    </template>
                  </el-menu-item>
                </template>
              </el-menu>
            </div>
            <div style="height: 32px;display:flex;justify-content: left;width: 200px;">
              <el-input
                v-model="inputConditions"
                style="width: 200px;"
                placeholder="白酒"
                @change="onChangeInput"
              >
                <template #prefix>
                  <el-icon class="el-input__icon" @click="onChangeInput"><search /></el-icon>
                </template>
              </el-input>
            </div>
          </div>
          <div class="navigation-right">
            <div class="user-info-box">
              <el-dropdown
                @command="command"
                :append-to-body="false"
                class="login-info"
              >
            <span>
<!--              <div class="avatar-box" @click="onClickAvatar"></div>-->
              <!--              <span class="admin">您好：小飞</span>-->
            </span>
                <template #dropdown>
                  <el-dropdown-menu :append-to-body="false">
                    <el-dropdown-item :command="1">
                      <img src="@/assets/images/loginOut.png" alt="" />
                      退出登录</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </el-header>
      </el-affix>
      <el-container class="main">
        <router-view />
      </el-container>
    </div>
  </el-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from 'vue'
import router from '../router/index'
import { ILoginInfo, IModules } from '@/typings/login'
import { RouteRecordName, useRouter } from 'vue-router'
import { Store, useStore } from 'vuex'
import { IState } from '../store/typesState'
import useFormatPath from '@/common/useFormatPath'
import { navigation } from '@/request/api/home'
import { ElMessage } from 'element-plus'

export default defineComponent({
  data () {
    return {
      userInfo: {} as ILoginInfo
    }
  },
  computed: {
    // userInfoAdmin () {
    //   return (this as any).userInfo.name
    //     ? (this as any).userInfo?.name
    //     : '请登录'
    // }
  },
  methods: {
    command (index: number) {
      if (index === 0) {
        //  跳转个人信息页面
        this.$router.push({ path: '/userInfo' })
      } else {
        //  系统退出
        (this as any)
          .$confirm('确认退出登录吗', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(() => {
            // loginOut().then((res) => {
            //   if (res.code === 200) {
            //     localStorage.clear()
            //     this.$router.replace('/login');
            //     (this as any).$message({
            //       type: 'success',
            //       message: res.msg
            //     })
            //   }
            // })
          })
      }
    }
  },
  setup () {
    const router = useRouter()
    const inputConditions = ref('')
    const store: Store<IState> = useStore()
    const activeIndex = ref('首页')
    const { formatPath } = useFormatPath()
    const defaultActive = ref<RouteRecordName>('')
    const tabs = store.state.loginUser.modules as []
    const tabList = reactive<any>(router.options.routes[0].children)
    const navigationLit = ref<any>([])
    // console.log('路径都有些啥', tabList)
    const menuData = ref<any>([])
    const menuActive = (menu: IModules) => {
      let currentMenu = menu.children ? menu.children : []
      if (menu.view === 'ProjectLibrary') {
        loadPlMenu().then((menuOverridden) => {
          currentMenu = menuOverridden
        })
      }
      if (currentMenu.length) {
        menuData.value = currentMenu
        localStorage.setItem('currentMenu', JSON.stringify(currentMenu))
      }
    }
    onMounted(() => {
      getNavigation()
    })
    const getNavigation = () => {
      navigation().then((res:any) => {
        if (res.data.code === 0) {
          console.log('导航数据：', res)
          navigationLit.value = res.data.data
          store.commit('projectNavigationList', navigationLit.value)
        }
      })
    }
    const loadPlMenu = (): Promise<IModules[]> => {
      return Promise.resolve([])
    }
    const onClickAvatar = () => {
      router.replace({
        path: '/Home/personalCenter'
      })
    }
    const onClickSubset = (item:any, data:any) => {
      activeIndex.value = data.categoryName
      router.replace({
        path: '/Home/news',
        query: { categoryId: item.categoryId, categoryName: item.categoryName }
      })
      inputConditions.value = ''
    }
    const onClickCategoryName = (item:any) => {
      if (item.izLink === 1) {
        location.assign(item.targetUrl)
      } else {
        tabList.forEach((ir:any) => {
          if (ir.meta.title === item.categoryName) {
            router.replace({
              // item.targetUrl   ir.path
              path: item.targetUrl,
              query: { targetParams: item.targetParams, isShow: 0, categoryName: item.categoryName }
            })
          }
        })
      }
      inputConditions.value = ''
    }
    // 输入框事件
    const onChangeInput = () => {
      if (inputConditions.value) {
        router.replace({
          path: '/Home/search',
          query: { inputConditions: inputConditions.value }
        })
        inputConditions.value = ''
      } else {
        ElMessage.success('内容不能为空！')
      }
    }
    const handleSelect = (value: any) => {
      activeIndex.value = value
    }
    return {
      tabs,
      store,
      router,
      activeIndex,
      navigationLit,
      handleSelect,
      // route,
      menuData,
      tabList,
      formatPath,
      menuActive,
      defaultActive,
      onClickAvatar,
      getNavigation,
      onClickSubset,
      onClickCategoryName,
      inputConditions,
      onChangeInput
      // defaultNodeKey,
      // onNodeClicked,
    }
  }
})
</script>

<style lang="scss" scoped>
.zhang-shufeu {
  width: 52px;
  height: 35px;
  color: #b3c3d1;
}
:deep(.el-dropdown) {
  color: #fff;
  cursor: pointer;
}
.top {
  //background-color: #092b5a;
  //background: #3F3E58;
  background: #FFFFFF;
  top: 1px;
  height: 50px;
  width: 100%;
}
.navigation-lift {
  width: 90%;
  height: 34px;
  line-height: 34px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-top: 6px;
  opacity: 1;
  //margin-top: 12px;
  float: left;
  display: flex;
  .navigation-logo {
    width: 14%;
    text-align: right;
    //margin-right: 20px;
    img {
      width: 43px;
      height: 36px;
    }
  }
  .navigation-middle {
    width: 82%;
    text-align: left;
    .el-menu--horizontal {
      height: 34px;
      //background: #3F3E58;
      background: #FFFFFF;
      :deep(.el-menu-item) {
        color: #333333 !important;
        padding: 14px !important;
      }
    }
    .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
      //background-color: #3F3E58;
      color: #004ebb !important;
      outline: none;
    }
    //.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover
    .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
      color: #004ebb !important;
      //background-color: #3F3E58;
    }
    .el-menu-item.is-active {
      color: #023692 !important;
      border-bottom: 2px solid #023692;
    }
    :deep(.el-menu) {
      //border-bottom: 1px solid #3F3E58;
      border-bottom: 1px solid #FFFFFF;
    }
  }
}
//.navigation-right {
//  width: 15%;
//  color: #5cabc9;
//  margin-top: 12px;
//  float: right;
//  .user-info-box {
//    margin-top: 5px;
//    margin-right: 10px;
//    float: right;
//    height: 21px;
//  }
//}
.mainMenu {
  background-color: #004ebb;
  height: 60px;
  :deep(.el-menu-item) {
    float: left;
    height: 60px;
    line-height: 60px;
    font-weight: 400;
    margin: 0;
    border-bottom: 2px solid transparent;
    width: auto;
    font-family: Microsoft YaHei;
  }
}
.avatar-box {
  width: 32px;
  height: 32px;
  border-radius: 40px;
  display: inline-block;
}
.subset-options {
  text-align: center;
  height: 32px;
  line-height: 32px;
}
.subset-options:hover {
  color: #004ebb;
  background: #e6f3f8;
}
:deep(.el-input__wrapper) {
  background: #f2f2f2;
}
.el-input__icon {
  vertical-align: text-top;
  cursor: pointer;
}
:deep(.el-menu-item) {
  font-size: 18px;
}
</style>
