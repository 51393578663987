import axios from 'axios'

const instance = axios.create({
  timeout: 60000,
  baseURL: '/bde-www/' // http://192.168.3.7:5010/    https://bde.52api.net/
})

axios.defaults.headers.common['Content-Type'] = 'application/json';

// 请求拦截器
instance.interceptors.request.use(
  function (config) {
    console.log('请求发送:', config.url)
    return config
  }
)

// 响应拦截器
instance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log('接收响应:', error.config.url)
  return Promise.reject(error)
})

export default instance
