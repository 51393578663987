import { computed } from 'vue'
import { Store, useStore } from 'vuex'
import { IState } from '@/store/typesState'

export default function () {
  const store: Store<IState> = useStore()
  const formatPath = computed(() => {
    return (path: string) => {
      if (path) {
        return path.replace(process.env.VUE_APP_FILE_URL_PREFIX as string,
          process.env.VUE_APP_FILE_URL as string) + '?token=' + store.state.token
      }
      return path
    }
  }) as any

  return {
    formatPath
  }
}
