import { ILogin, ILoginInfo, IModules } from '@/typings/login'
import { IState } from './typesState'

export default {

  setUserInfo (state: IState, userInfo: ILoginInfo) {
    state.loginUser = userInfo
    state.token = userInfo.token || ''
  },
  setLeftMenu (state: IState, menu: IModules[]) {
    state.leftMenu = menu
  },
  setRememb (state: IState, rememb: ILogin) {
    state.rememb = rememb
  },
  setCurrentTab (state: IState, currentTab: string) {
    state.currentTab = currentTab
  },
  changeProjectTypeList (state: IState, list: []) {
    state.projectTypeList = list
  },
  projectNavigationList (state: IState, list: []) {
    state.projectList = list
  }
}
