// import { IRequestStatus, PageDto } from '../../../typings/index'
// import ajax from '../../../request/axios'
import {get, post} from '@/request/http'

export interface Reject {
  projectId: string,
  rejectReason: string
}

// export interface ProjectUpdateParams {
//   address?: string;
//   areaCode?: string;
//   areaName?: string;
//   cityCode?: string;
//   cityName?: string;
//   // investmentFuncs?: ProjectInvestmentFuncParams[];
//   projectName?: string;
//   provinceCode?: string;
//   provinceName?: string;
//   projectId: string;
// }
export interface ProjectName {
  code: string
}
export interface NodesType {
  ancestors:string
  categoryId:string
  categoryName:string
  createBy:string
  createTime:string
  delFlag:string
  description:string
  izLink:string
  nodes:[]
  orderNum:number
  params:object
  parent:string
  parentId:number
  parentName:string
  publishTime:string
  status:number | string
}
export interface Meta {
  isShow: number,
  title: string,
  icon: string
}

export interface TabListType {
  component: any | object
  meta: Meta[] | any
  name: string
  path: string
}
export interface NavigationTitles {
  ancestors: string
  categoryId: number
  categoryName:string
  createBy:string
  createTime:string
  delFlag: string | number
  description: string | number
  izLink:number
  nodes: NodesType[],
  orderNum:string | number
  params:object
  parent: string
  parentId:number
  parentName:string
  publishTime:string
  remark:string
  searchValue:string
  sort:number
  status:number | string
  targetParams:string
  targetUrl:string
  updateBy:string
  updateTime:string
}


// export const updateProject = (
//   data: ProjectUpdateParams
// ): Promise<IRequestStatus> =>
//   ajax('engineering/project/update', data, 'post') http//192.168.3.72:9080/

// export const banner = (data: ProjectName): Promise<IRequestStatus> =>
//   ajax('http//192.168.3.72:9080/cms-api/banner/', data, 'get')
// -------------首页banner-----------------
export const banner = (data:string) => get('cms-api/album/' + data, {})
// -------------首页banner-----------------
export const ad = (data:string) => get('cms-api/ad/' + data, {})
// -------------导航数据--------------
export const navigation = () => get('cms-api/navigation', {})
// -------------网站的基本信息-------------
export const siteinfo = (data:string) => get('cms-api/siteinfo/' + data, {})
// -------------首页时时新闻---------------
export const newst = () => get('cms-api/newst', {})
// ------------新闻智讯-----------------
export const list = (categoryId:string, data:any) => get('cms-api/list/' + categoryId, { params: data })
// ------------文章搜索-----------cms-api/search
export const search = (data:any) => post('cms-api/search', data)
// ------------文章详情------------------cms-api/article/articleId
export const article = (articleId:string) => get('cms-api/article/' + articleId, {})
// ------------友情链接------------------code
export const links = (code:any) => get('cms-api/links/' + code, {})


// ------------------------------------------商品类--------------
// --------------商品分类-------------------
export const category = () => get('cms-api/goods/category', {})
// --------------推荐商品-------------------
// export const recommend = () => get('cms-api/goods/recommend', {})
export const recommend = (data:any) => post('cms-api/goods/recommend', data)
// --------------未知商品-----------------
// export const goods = () => get('goods/activity/goods', {})

export const goodSlist = (data:any) => post('cms-api/goods/list', data)
// ---------------条件查询----------------
export const queryparams = (data:any) => get('cms-api/goods/queryparams', { params: data })
// ---------------营销活动----------------
export const activitys = () => get('cms-api/goods/activitys', {})
// ---------------商品活动内容id-----------
export const goods = (activeId:any) => get('cms-api/goods/activity/goods', { params: activeId })
// ---------------商品详情---------------
export const getProduct = (productId:any) => get('cms-api/goods/product/' + productId, {})
